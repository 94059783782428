<template>
  <div>
    <div class="demo">
      <div class="demo-top" v-if="txtTop">宣讲还未开始，仅能看宣讲详情</div>

      <video-player
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
      >
      </video-player>
    </div>

    <div class="center">
      <div class="topDiv"></div>
    </div>

    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      "
    >
      <div class="row" style="width: 375px">
        <div
          style="width: 33%; align-items: center; height: 30px"
          class="column"
          @click="selectimg(1)"
        >
          <div style="font-size: 16px; color: #7f7f7f">留言互动</div>
          <div
            v-if="what == 1"
            style="
              width: 15px;
              background-color: #fdcc14;
              height: 3px;
              border-radius: 1.5px;
              margin-top: 5px;
            "
          ></div>
        </div>
        <div
          style="width: 33%; align-items: center; height: 30px"
          class="column"
          @click="selectimg(2)"
        >
          <div style="font-size: 16px; color: #7f7f7f">宣讲详情</div>
          <div
            v-if="what == 2"
            style="
              width: 15px;
              background-color: #fdcc14;
              height: 3px;
              border-radius: 1.5px;
              margin-top: 5px;
            "
          ></div>
        </div>
        <div
          style="width: 33%; align-items: center; height: 30px"
          class="column"
          @click="selectimg(3)"
        >
          <div style="font-size: 16px; color: #7f7f7f">招聘职位</div>
          <div
            v-if="what == 3"
            style="
              width: 15px;
              background-color: #fdcc14;
              height: 3px;
              border-radius: 1.5px;
              margin-top: 5px;
            "
          ></div>
        </div>
      </div>
    </div>

    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      "
    >
      <div v-if="what == 3">
        <div v-for="profession in professionList" :key="profession.id">
          <div class="line"></div>
          <div
            class="column"
            @click="getDescribe(profession.id)"
            style="width: 375px"
          >
            <div class="row">
              <div
                class="van-ellipsis"
                style="margin-left: 5%; font-size: 14px; width: 70%"
              >
                {{ profession.name }}
              </div>
              <div
                style="
                  color: #ff7d30;
                  font-size: 14px;
                  text-align: right;
                  width: 20%;
                "
              >
                {{
                  (profession.salary == 2
                    ? '3-4k'
                    : profession.salary == 3
                    ? '4-5k'
                    : profession.salary == 4
                    ? '5-10k'
                    : profession.salary == 5
                    ? '10-20k'
                    : profession.salary == 6
                    ? '20-50k'
                    : profession.salary == 7
                    ? '50k以上'
                    : '') + '/月'
                }}
              </div>
            </div>

            <div
              class="text"
              style="margin-left: 5%; font-size: 13px; margin-top: 5px"
              v-if="profession.cityName"
            >
              {{
                profession.cityName +
                ' | ' +
                (profession.educationalBackground == 2
                  ? '专科'
                  : profession.educationalBackground == 3
                  ? '本科'
                  : profession.educationalBackground == 4
                  ? '硕士'
                  : profession.educationalBackground == 5
                  ? '博士'
                  : '')
              }}
            </div>

            <div
              class="text"
              style="margin-left: 5%; font-size: 13px; margin-top: 5px"
              v-else-if="!profession.cityName"
            >
              {{
                '暂无 | ' +
                (profession.educationalBackground == 2
                  ? '专科'
                  : profession.educationalBackground == 3
                  ? '本科'
                  : profession.educationalBackground == 4
                  ? '硕士'
                  : profession.educationalBackground == 5
                  ? '博士'
                  : '')
              }}
            </div>

            <div
              class="text"
              style="margin-left: 5%; font-size: 13px; margin-top: 5px"
              v-else
            >
              {{ '暂无' }}
            </div>
          </div>
        </div>
      </div>

      <div>
        <div v-if="what == 2" style="margin-top: 10px; width: 375px">
          <div v-if="url">
            <van-swipe indicator-position="none" height="30vh">
              <van-swipe-item v-for="banner in url" :key="banner.id">
                <van-image
                  style="height: 30vh; width: 100%; background: #f5f5f5"
                  fit="contain"
                  :src="banner.url"
                />
              </van-swipe-item>
            </van-swipe>
          </div>
          <div
            class="column"
            style="margin: 20px; align-items: center; align-content: center"
          >
            <span style="width: 100%">公司详情</span>
            <div class="text" style="width: 100%" v-html="content"></div>
          </div>
        </div>
      </div>
      <div v-if="what == 1">
        <div
          class="column"
          style="justify-content: center; align-items: center; height: 50vh"
        >
          <div class="column" style="align-items: center">
            <div>互动聊天需要登录app才可以使用</div>
            <van-button
              @click="downloadHandler('student')"
              style="
                background-color: #fdcc14;
                color: #282828;
                width: 50%;
                height: 36px;
                margin-top: 10%;
                border-color: transparent;
              "
              round
              >立即去互动</van-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem, Button, Image } from 'vant';
import { videoPlayer } from 'vue-video-player/src';
import 'video.js/dist/video-js.css';
import { getTeachin } from '@/api/api';
import wxShare from '@/composables/wx';
import download from '@/composables/download';

export default {
  name: 'PreachShare',

  setup() {
    const { wxShareHandler } = wxShare();
    const { downloadHandler } = download();

    return {
      wxShareHandler,
      downloadHandler,
    };
  },

  data() {
    return {
      txtTop: false,
      obj: {},
      what: 2,
      title: '',
      teachinId: '',
      goUrl: '',
      professionList: [],
      url: [],
      content: '',
      urlImg: '',
      myurl: location.href.split('#')[0],
      shareConfig: {
        title: this.title,
        desc: '易校招邀请你要看双选会',
        share_url: window.location.href,
        share_img:
          'https://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/191598693566_.pic_hd.jpg',
      },
      time: '',
    };
  },

  computed: {
    playerOptions() {
      return {
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        autoplay: false,
        muted: false,
        loop: false,
        preload: 'auto',
        language: 'zh-CN',
        aspectRatio: '16:9',
        fluid: true,
        sources: [
          {
            type: 'video/mp4',
            src: '',
          },
        ],
        poster: 'https://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/teachin.png',
        notSupportedMessage: '宣讲时间：' + this.time,
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true,
        },
      };
    },
  },

  mounted() {
    this.teachinId = this.$route.query.id;
    this.getTeachinItem();
  },

  methods: {
    selectimg(go) {
      let _this = this;
      if (go == 1) {
        _this.what = 1;
      } else if (go == 2) {
        _this.what = 2;
      } else {
        _this.what = 3;
      }
    },

    getTeachinItem() {
      getTeachin({
        params: {
          id: this.teachinId,
        },
      }).then((res) => {
        this.wxShareHandler({
          title: res.title || '易校招宣讲会',
          url: window.location.href,
          icon: 'https://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/191598693566_.pic_hd.jpg',
          desc: '在线听宣讲，轻松投简历！',
        });

        this.obj = res;

        this.title = res.title;
        this.shareConfig.title = this.title;
        this.time = res.date + ' ' + res.startTime + ' - ' + res.endTime;

        res.content = this.htmlDecodeByRegExp(res.content);

        this.content = res.content;

        this.professionList = res.professions;

        this.url = res.imgs;
        if (res.teachinStatus == 1) {
          this.txtTop = true;
          this.goUrl = '';
          this.playerOptions.sources[0].src = '';
          return;
        }

        this.goUrl = res.vedio;
        this.playerOptions.sources[0].src = res.video;

        this.urlImg = res.videoBg;

        this.$set(
          this.shareConfig,
          'share_img',
          'https://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/191598693566_.pic_hd.jpg'
        );
      });
    },

    getDescribe(id) {
      this.$router.push({
        path: '/jobShare',
        query: {
          id,
        },
      });
    },

    goToMe() {
      window.location.href =
        'http://share.yxzapp.com/#/preachShare?id=' + this.teachinId + '&b=2';
    },

    imgUrl(item) {
      let str = 'http';
      if (item.indexOf(str) >= 0) {
        return item;
      } else {
        return 'http://yixiaozhao.lemondm.com' + item;
      }
    },

    htmlDecodeByRegExp: function (str) {
      var temp = '';
      if (str.length == 0) return '';
      temp = str.replace(/&amp;/g, '&');
      temp = temp.replace(/&lt;/g, '<');
      temp = temp.replace(/&gt;/g, '>');
      temp = temp.replace(/&nbsp;/g, ' ');
      temp = temp.replace(/&#39;/g, "'");
      temp = temp.replace(/&quot;/g, '"');
      return temp;
    },

    HTMLDecode(text) {
      let temp = document.createElement('div');
      temp.innerHTML = text;
      let output = temp.innerText || temp.textContent;
      temp = null;
      return output;
    },
  },

  components: {
    videoPlayer,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Button.name]: Button,
    [Image.name]: Image,
  },
};
</script>

<style lang="scss" scoped>
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.topDiv {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
}
.column {
  display: flex;
  flex-direction: column;
}
.text {
  font-size: 14px;
  text-align: left;
  height: auto;
  color: #7f7f7f;
}

video:-webkit-full-page-media {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}

video {
  object-fit: contain;
}

.demo {
  display: inline-block;
  width: 100%;
  height: auto;
  text-align: center;
  line-height: 100px;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.demo:hover {
  display: block;
}

.demo :deep(.vjs-modal-dialog .vjs-modal-dialog-content) {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  top: auto;
  padding: 5px;
  color: #fff;
  font-size: 12px;
  z-index: 1;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
}

:deep(.vjs-custom-skin > .video-js .vjs-big-play-button) {
  top: 50%;
  left: 50%;
  margin-left: -1.5em;
  margin-top: -1em;
  background-color: rgba(0, 0, 0, 0.45);
  font-size: 3.5em;
  height: 2em !important;
  line-height: 2em !important;
  margin-top: -1em !important;
}

:deep(.vjs-error .vjs-error-display:before) {
  color: #fff;
  content: '';
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.6em;
  left: 0;
  line-height: 1;
  margin-top: -0.5em;
  position: absolute;
  text-shadow: 0.05em 0.05em 0.1em #000;
  text-align: center;
  top: 50%;
  vertical-align: middle;
  width: 100%;
}

.demo-top {
  width: 100%;
  position: absolute;
  top: 0px;
  color: #fff;
  z-index: 1;
  line-height: 30px;
  background: rgba(255, 255, 255, 0.4);
  font-size: 12px;
  backdrop-filter: blur(10px);
}
</style>
